@import '@/styles/artifacts.scss';








































































.rooms-container {
  border: 1px solid #ccc7cc;
}
.add-room {
  float: right;
}

.room {
  border-radius: 5px;
  position: relative;
  min-height: 100px;
  padding: 12px;
  background-color: #f7f7f7;

  .fields {
    width: 126px;
    padding-top: 20px;
  }

  &:hover {
    .close {
      display: block;
    }
  }

  .name {
    font-weight: 900;
  }

  .close {
    display: none;
    position: absolute;
    right: 8px;
    top: 8px;
    padding: 2px;
    cursor: pointer;
    width: 12px;
    height: 12px;
  }

  .icon {
    width: 16px;
    height: 16px;
  }
}
