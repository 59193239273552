@import '@/styles/artifacts.scss';





















































.node {
  display: block;
  line-height: 28px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.node:hover {
  color: colorVodafone(vodafone-red);
}
