@import '@/styles/artifacts.scss';











































































































.label {
  font-size: fontSize(fs-150);
}

.fields {
  max-width: 480px;
}
