@import '@/styles/artifacts.scss';
























































































































.property-editor-floor {
  position: relative;
  &:not(:first-child) {
    border-top: 1px solid #ccc7cc;
  }
}

.right-column {
  background-color: #f7f7f7;
}

.menu-container {
  position: absolute;
  right: -44px;
  top: 12px;

  .dropdown {
    position: absolute;
    right: 0px;
    top: 28px;
    z-index: 1;
    padding: 5px 16px 16px;
    display: flex;
    justify-content: center;
    background-color: white;
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.12);
    width: 100px;
    float: right;
  }
  .actions {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    li {
      cursor: pointer;
    }
  }
}

.name {
  font-weight: 900;
  font-size: fontSize(fs-150);
  padding-left: 4px;
  display: inline-block;
}

.edit-button {
  text-align: center;
}
