@import '@/styles/artifacts.scss';


















































































































































































































.summary {
  margin-top: 20px;
}
span.error {
  color: colorVodafone(vodafone-red);
}
