@import '@/styles/artifacts.scss';


















































































































.list {
  margin: 0;
  padding: 0;
}

.drop-space {
  height: 10px;
  border: 1px dotted colorVodafone(dark-grey);
}
