@import '@/styles/artifacts.scss';























































































































































































































































































































































































.scopes {
  margin-left: 12px;
  display: flex;
}
