@import '@/styles/artifacts.scss';































































































































































































































.floors {
  position: relative;
  margin: 0 16px 23px;
  min-height: 100px;
  padding-right: 50px;
}
.floors:not(.roof) {
  border-left: 1px solid #ccc7cc;
  border-right: 1px solid #ccc7cc;
}
.floors::before,
.floors::after {
  content: '';
  display: block;
  position: absolute;
  bottom: -12px;
  left: -8px;
  right: -8px;
  height: 10px;
  border: 1px solid #ccc7cc;
}
.floors::after {
  bottom: -23px;
}
.roof {
  background: url(./roof.svg) right 10px bottom -1px no-repeat;
  padding-right: 325px;
}

.roof::before,
.overground::after {
  left: -16px;
  right: -16px;
}
.underground::after {
  display: none;
}

.relative {
  position: relative;
}

.dropdown {
  padding: 5px 16px 16px;
  display: flex;
  justify-content: center;
  background-color: white;
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.12);
  width: 100px;
  float: left;
}
.actions {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  li {
    cursor: pointer;
  }
}
