@import '@/styles/artifacts.scss';

























































































































































.text-light {
  color: colorVodafone(grey);
}
.text-overflow {
  @include textOverflow();
}
