@import '@/styles/artifacts.scss';






















































































































































































































































































































































































































































































































































































































































































































































































































































































































.top-button {
  float: right;
}

.save {
  font-size: fontSize(fs-200);
  margin: 16px 0;
  margin: auto;
}

.list {
  margin-top: 20px;
  padding: 0 5px;
  color: colorVodafone(grey);
}

.properties-panel {
  max-width: 800px;
}
