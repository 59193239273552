@import '@/styles/artifacts.scss';
















































.header {
  position: relative;
  .close {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 4px;
    cursor: pointer;
  }
}
