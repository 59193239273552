@import '@/styles/artifacts.scss';











































































































.property-subdivision-editor {
  border-radius: 5px;
  position: relative;
  min-height: 100px;
  padding: 12px;

  .fields {
    width: 126px;
    padding-top: 20px;
  }

  &:hover {
    .close {
      display: block;
    }
    .edit-rooms-button {
      display: block;
    }
  }

  .name {
    font-weight: 900;
  }

  .close {
    display: none;
    position: absolute;
    right: 8px;
    top: 8px;
    padding: 2px;
    cursor: pointer;
    width: 12px;
    height: 12px;
  }

  .number-input {
    padding-left: 4px;
    display: inline-block;
  }

  .icon {
    width: 16px;
    height: 16px;
  }
}

.edit-rooms-button {
  position: absolute;
  bottom: 16px;
  right: 10px;
  padding: 4px;
  font-size: fontSize(fs-50);
  color: colorVodafone(vodafone-red);
  display: none;
}
