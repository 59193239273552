@import '@/styles/artifacts.scss';


















































































.node {
  display: flex;
  align-items: center;
  font-size: fontSize(fs-100);
  line-height: lineHeight(lh-xl);
  font-weight: 700;
  color: colorVodafone(black);
}
.node:not(.disabled):hover {
  color: colorVodafone(vodafone-red);
}
.name-column {
  overflow: hidden;
  display: flex;
  align-items: center;
  .name {
    flex: 1 0 0;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.arrow-down {
  width: 8px;
  margin-right: 8px;
  cursor: pointer;
}

.icon {
  height: 16px;
  width: 16px;
  display: block;
  padding: 0 4px 0 4px;
}

.details {
  display: flex;
  justify-content: flex-end;
}
