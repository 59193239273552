@import '@/styles/artifacts.scss';

















































































































.status_labels {
  display: flex;
  flex-direction: column;
  span {
    &.label {
      width: 108px;
      margin: 2px auto;
      text-align: center;
      padding: 0;
    }
    &.user {
      width: auto;
      background-color: white;
      color: black;
    }
  }
}
.expired {
  color: colorVodafone(grey);
}
